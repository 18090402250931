import React from "react";
import "./polymino-nav.css";
import {Home, Wiki, News, Downloads, Steam, DiscordGIF} from "./img/images";

export default function Navbar({active})
{
    const pages = [
        {
            img: Home,
            key: "home",
            url: "/"
        },
        {
            img: News,
            key: "news",
            url: "/news"
        },
        {
            img: Wiki,
            key: "wiki",
            url: "/wiki"
        },
        {
            img: Downloads,
            key: "downloads",
            url: "/downloads"
        }
    ]
    .map(pg => {
            const isActive = active === pg.key;
            const linkClass = isActive ? "active" : null;
            const overlayClass = isActive ? "activeOverlay" : "inactiveOverlay";
            return <li key={pg.key}>
                <div className={overlayClass}/>
                <a className={linkClass} href={pg.url}>
                    <img src={pg.img} alt={pg.key.toUpperCase()}/>
                </a>
            </li>;
        }
    );

    pages.push(<li className="discordGIF" key="discord">
        <a target="_blank" rel="noreferrer"
            href="https://discord.gg/TZgUwZzpZx"
        >
            <img src={DiscordGIF} alt="Join our Discord server!"/>
        </a>
    </li>);
    pages.push(<li className="steamLink" key="steam">
        <div className="inactiveOverlay"/>
        <a target="_blank" rel="noreferrer"
            href="https://store.steampowered.com/app/2537550/Polymino/"
        >
            <img src={Steam} alt="Buy on Steam"/>
        </a>
    </li>);

    return <div id="navbar">
        <ul>
            {pages}
        </ul>
    </div>;
}
