import React from "react";
import {Link, useParams} from "react-router-dom";

function categoryName(cat)
{
    if (cat === "classic")
    {
        return "Classic";
    }
    else if (cat === "junkatk")
    {
        return "Junk Attack";
    }
    else if (cat === "puzzle")
    {
        return "Puzzle";
    }
    else
    {
        return "(unknown)";
    }
}

/**
 * @param {Object} props
 * @param {string} props.category
 * @param {string} props.page
 * @param {boolean} [props.showCategory]
 * @param {boolean} [props.top]
 */
export default function WikiLink({category, page, showCategory, top})
{
    showCategory = !!showCategory;
    top = !!top;

    const {wikiCat, wikiPage} = useParams();
    // Don't make it a link if we're on the page
    if (category === wikiCat && page === wikiPage)
    {
        return page;
    }
    else
    {
        return <Link to={`/wiki/${category}/${page}${top ? "#top" : ""}`}>
            {showCategory && categoryName(category) + " - "}{page}
        </Link>;
    }
}
