import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import Home from "./Home";
import News from "./News";
import WikiContainer from "./wiki/WikiContainer";
import WikiLanding from "./wiki/Landing";
import WikiPage from "./wiki/Page";
import Downloads from "./Downloads";

export default function App()
{
    return <Router>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/news" element={<News/>}/>
            <Route path="/wiki" element={<WikiContainer/>}>
                <Route index element={<WikiLanding/>}/>
                <Route path="/wiki/:wikiCat/:wikiPage" element={<WikiPage/>}/>
            </Route>
            <Route path="/downloads" element={<Downloads/>}/>
        </Routes>
    </Router>;
}
