import React from "react";
import AbilityMeter from "./AbilityMeter";
import loadouts from "./pages/junkatk/data/loadouts.json";

import {
    junkModDetails,
    junkTransferDetails,
    bombAbilityDetails,
    transferBombDetails,
    queueShapeModDetails,
    onShatterGlobalDetails,
    junkSpawnDetails,
    timedAbilityDetails
} from "./AbilityToStr";

/**
 * @returns {[[number, number, number], number]}
 */
function getMeterLevels(loadout)
{
    const levels = loadout.levels ?? [400, 400, 400];
    const bombCost = loadout.bomb_cost ?? 200;

    return [levels, bombCost];
}

export default function LoadoutsList()
{
    /**
     * @param {string} name 
     */
    const h3ID = (name) => {
        return name.replaceAll(/[^a-zA-Z0-9]/g, '-').toLowerCase();
    };

    return <div>{
        Object.entries(loadouts).map(([name, loadout]) => <div
            className="loadoutDiv"
            key={name}
        >
            <div>
                <h3 id={h3ID(name)}>{name}</h3>
                <blockquote><p>{loadout.summary}</p></blockquote>
                <Abilities loadout={loadout}/>
            </div>
            <AbilityMeter loadout={loadout}/>
        </div>)
    }</div>;
}

function Abilities({loadout})
{
    // Need to create a copy so re-renders don't create incorrect calculations
    const levels = Array.from(getMeterLevels(loadout)[0]);
    levels[1] += levels[0];
    levels[2] += levels[1];
    return <>{[0, 1, 2].map(i => <Ability
        key={i}
        level={i + 1}
        ability={loadout.abilities[i]}
        cost={levels[i]}
    />)}</>;
}

function Ability({ability, cost, level})
{
    const builders = new Map([
        ["junk_mod", junkMod],
        ["junk_transfer", junkTransfer],
        ["bomb", bombAbility],
        ["transfer_bomb", transferBomb],
        ["queue_shapes", queueShapeMod],
        ["on_shatter", onShatter],
        ["junk_spawn", junkSpawn],
        ["timed", timedAbility],
    ]);
    return builders.get(ability.type)(ability, cost, level);
}

function AbilityHeader(abName, cost, level, desc)
{
    const ulStyle = {
        fontWeight: "normal",
        listStyleType: "none",
        paddingLeft: 20
    };

    const h4Style = {
        marginTop: 15,
        marginBottom: 15
    };

    return <h4 style={h4Style}>
        {level}. {abName} (cost: {cost})
        <ul style={ulStyle}><li>- {desc}</li></ul>
    </h4>;
}

function junkMod(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Junk Modifier", cost, level, "Applies one or more effects to one or more junk pieces.")}
        {junkModDetails(ability)}
    </div>;
}

function junkTransfer(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Junk Transfer", cost, level, "Transfers pieces from your board to each opponent's board.")}
        {junkTransferDetails(ability)}
    </div>;
}

function bombAbility(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Bomb", cost, level, "A bomb which can have unique shapes.")}
        {bombAbilityDetails(ability)}
    </div>;
}

function transferBomb(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Transfer Bomb", cost, level, "A bomb which transfers junk pieces instead of destroying them.")}
        {transferBombDetails(ability)}
    </div>;
}

function queueShapeMod(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Queue Shape Modifier", cost, level, "Modifies the shapes of junk pieces in the queue.")}
        {queueShapeModDetails(ability)}
    </div>;
}

function onShatter(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Global On-shatter", cost, level, "Any junk destroyed for a limited time will have an on-shatter effect.")}
        {onShatterGlobalDetails(ability)}
    </div>;
}

function junkSpawn(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Junk Spawn", cost, level, "Immediately spawns one or more junk pieces.")}
        {junkSpawnDetails(ability)}
    </div>;
}

function timedAbility(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Timed Effect", cost, level, "A miscellaneous timed effect.")}
        {timedAbilityDetails(ability)}
    </div>;
}
